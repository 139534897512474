import React from 'react'
import { AffiliateDashboardLayout } from 'components/AffiliateDashboard'
import ClientManageBillingView from 'domains/AffiliateDashboard/ClientManageBilling'
import withAuth from '../../../hocs/with-auth'

const redirectUrl = '/affiliate-dashboard/login?redirectUrl=/affiliate-dashboard/clients/manage-billing'

const ManageBilling = ({ location }) => (
  <AffiliateDashboardLayout>
    <ClientManageBillingView location={ location } />
  </AffiliateDashboardLayout>
)

export default withAuth(ManageBilling, redirectUrl)
