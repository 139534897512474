import styled from 'styled-components'
import { lightDarkBlue } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  margin: 1rem 8rem;
  font-family: 'Open Sans', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  color: ${lightDarkBlue};
  min-height: 75vh;
  word-break: break-all;

  a {
    text-decoration: none;
    color: #1275fa;
    line-height: 1.29;
    letter-spacing: 0.25px;
  }

  h2 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    font-size: 1.5rem;
    display: flex;
    align-items: center;

    > svg {
      margin-right: 0.75rem;
    }
  }

  ${IS_FRAME_TABLET} {
    margin: 1rem 6rem;
  }

  ${IS_FRAME_MOBILE} {
    margin: 1rem 1rem 2rem;
    min-height: 75vh;

    h2 {
      font-size: 1.1rem;
    }
  }
`
