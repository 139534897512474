import React, { 
  useCallback, 
  useEffect, 
  useMemo, 
  useState 
} from 'react'
import { navigate } from 'gatsby'
import { useToasts } from 'react-toast-notifications'
import { AffiliateClientsURL } from 'commons/API'
import AffiliateAPI from 'commons/API/affiliate'
import { 
  EmptyState,
  Pagination, 
  ShowPerPage,
  Table 
} from 'components/AffiliateDashboard'
import dayjs from 'dayjs'
import { formatMoney } from 'utils'
import { 
  DesktopWrapper,
  MobileWrapper,
  SectionTitle, 
  HistoryCard, 
  Flex,
  Status,
  Key, 
  Value 
} from './styles'

const TITLES = [
  {
    name  : 'Date',
    key   : 'date',
    width : '100px'
  },
  {
    name  : 'Package',
    key   : 'package',
    width : '150px'
  },
  {
    name  : 'Period',
    key   : 'period',
    width : '150px'
  },
  {
    name : 'Payment Method',
    key  : 'payment_method'
  },
  {
    name : 'Total',
    key  : 'total'
  },
  {
    name : 'Status',
    key  : 'status'
  },
  {
    name : 'Invoice',
    key  : 'invoice'
  }
]

const BillingHistory = ({
  clientId,
  pageQuery,
  perPageQuery
}) => {

  const { addToast } = useToasts()

  const [billingHistory, setBillingHistory] = useState({
    transactions : [],
    meta         : {
      total      : 0,
      total_page : 1
    }
  })
  const [isLoading, setIsLoading] = useState(true)

  const fetchBillingHistory = useCallback(async (pageQuery, perPageQuery) => {
    await AffiliateAPI.post(AffiliateClientsURL.PostClientBillingHistoryList(clientId), 
      {},
      { 
        params: {
          per_page : perPageQuery || 10,
          page     : pageQuery || 1
        } 
      }
    )
      .then((response) => {
        const data = response.data
        setBillingHistory(data)
        setIsLoading(false)
      })
      .catch((error) => addToast(error.response.data.message, { appearance: 'warning' }) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  useEffect(() => {
    fetchBillingHistory(pageQuery, perPageQuery)
  }, [fetchBillingHistory, pageQuery, perPageQuery])

  const handleFormatDataPerRow = useCallback(() => billingHistory.transactions.map((transaction) => (
    {
      date           : dayjs(transaction.created_at).format('DD MMM YYYY'),
      package        : transaction.account_package.name,
      period         : `${ dayjs(transaction.billing_start_date).format('DD MMM') } - ${ dayjs(transaction.billing_end_date).format('DD MMM YYYY') }` ,
      payment_method : transaction.payment_method.name,
      total          : formatMoney(transaction.amount, { currency: transaction.currency.name }),
      status         : <Status status={ transaction.transaction_status.name }>{ transaction.transaction_status.name }</Status>,
      invoice        : <a href={ transaction.hosted_invoice_url } target="_blank" rel="noreferrer">{ transaction.invoice_no }</a>
    }
  )), [billingHistory.transactions])

  const dataPerRow = useMemo(() => handleFormatDataPerRow(), [handleFormatDataPerRow])

  const MobileBillingHistory = () => {

    if(!isLoading && !billingHistory.transactions.length) return <EmptyState text={ 'Sorry, you don\'t have transaction history yet' } />

    return (
      billingHistory.transactions.map((transaction, id) => (
        <HistoryCard key={ id }>
          { /* col 1 */ }
          <div>
            <Key>
              Date
            </Key>
            <Value>
              { dayjs(transaction.created_at).format('DD MMM YYYY') }
            </Value>
          </div>

          <div>
            <Key>
              Package
            </Key>
            <Value>
              { transaction.account_package.name }
            </Value>
          </div>

          <div>
            <Key>
              Period
            </Key>
            <Value>
              { `${ dayjs(transaction.billing_start_date).format('DD MMM') } - ${ dayjs(transaction.billing_end_date).format('DD MMM YYYY') }` }
            </Value>
          </div>

          { /* col 2 */ }
          <div>
            <Key>
              Payment Method
            </Key>
            <Value>
              { transaction.payment_method.name }
            </Value>
          </div>

          <div>
            <Key>
              Total
            </Key>
            <Value>
              { formatMoney(transaction.amount, { currency: transaction.currency.name }) }
            </Value>
          </div>

          <div>
            <Key>
              Status
            </Key>
            <Status status={ transaction.transaction_status.name }>
              { transaction.transaction_status.name }
            </Status>
          </div>

          <div>
            <Key>
              Invoice
            </Key>
            <Value>
              <a href={ transaction.hosted_invoice_url } target="_blank" rel="noreferrer">{ transaction.invoice_no }</a>
            </Value>
          </div>
        </HistoryCard>
      ))
    )
  }

  return(
    <>
      <SectionTitle>
        History
      </SectionTitle>

      <Flex>
        <ShowPerPage 
          selected={ perPageQuery }
          onChange={ (number) => {
            const query = [`client_id=${clientId}`, `page=${1}`, `per_page=${number}` ].join('&')
            const updatedUrl = [location.pathname,'?', query].join('')
            navigate(updatedUrl)
          } }
        />

        <Pagination
          currentNumber={ Number(pageQuery) || 1 }
          lastNumber={ billingHistory.meta.total_page }
          total={  billingHistory.meta.total } 
          onClick={ (number) => {
            const query = [`client_id=${clientId}`, `page=${number}`, `per_page=${perPageQuery||10}` ].join('&')
            const updatedUrl = [location.pathname,'?', query].join('')
            navigate(updatedUrl)
          } }
        />
      </Flex>

      <DesktopWrapper>
        <Table
          titles={ TITLES }
          data={ dataPerRow }
          isLoading={ isLoading }
          maxHeight='100%'
        /> 
      </DesktopWrapper>

      <MobileWrapper>
        <MobileBillingHistory />
      </MobileWrapper>
    </>
  )
}

export default BillingHistory
