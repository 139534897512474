import React, { 
  useEffect, 
  useCallback, 
  useState, 
  useMemo
} from 'react'
import { Link } from 'gatsby'
import dayjs from 'dayjs'
import { IconCompany } from '@qlue/icon-kit'
import {
  CurrentPlanBox,
  Grid,
  SectionTitle,
  Detail,
  Flex,
  PaymentButton,
  Status,
  CountDay,
  Arrow,
  ShowMore
} from './styles'
import { AffiliateClientsURL } from 'commons/API'
import AffiliateAPI from 'commons/API/affiliate'
import { formatMoney } from 'utils'

const CurrentPlan = ({ clientId }) => {

  const [currentPlan, setCurrentPlan] = useState({
    account: {
      name: ''
    }
  })
  const [isLoading, setIsLoading] = useState(true)

  const [dynamicBtn, setDynamicBtn] = useState({})
  const [isShowMore, setIsShowMore] = useState(false)

  const fetchCurrentPlan = useCallback(async () => {
    await AffiliateAPI.get(AffiliateClientsURL.GetClientCurrentBilling(clientId))
      .then((response) => {
        const data = response.data
        setCurrentPlan(data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
      })
  }, [clientId])

  const fetchRenewBtn = useCallback(async () => {
    await AffiliateAPI.get(AffiliateClientsURL.GetRenewSubscriptionBtn(clientId))
      .then((response) => {
        const data = response.data
        if(data.title && data.url) setDynamicBtn(data)
        else setDynamicBtn(null)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [clientId])

  useEffect(() => {
    fetchCurrentPlan()
    fetchRenewBtn()
  }, [fetchCurrentPlan, fetchRenewBtn])

  const countDayLeft = useCallback(() => {
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const firstDate = new Date()
    const secondDate = new Date(currentPlan.billing_end_date)

    return Math.round(Math.abs((firstDate - secondDate) / oneDay))
  }, [currentPlan.billing_end_date])

  const dayLeft = useMemo(() => countDayLeft(), [countDayLeft])

  return(
    <>
      <h2>
        <IconCompany /> { currentPlan?.account?.name }
      </h2>
      <SectionTitle>
        Current Plan
      </SectionTitle>
      { !isLoading && (
        <CurrentPlanBox>
          <Grid isShowMore={ isShowMore }>

            { /* col 1 */ }
            <div>
              <Detail>
                <div>
                  Join Date
                </div>
                <div> 
                  :
                </div>
                <div> 
                  { dayjs(currentPlan.account.created_at).format('DD MMMM YYYY') } 
                </div>
              </Detail>

              <Detail>
                <div>
                  Package
                </div>
                <div> 
                  :
                </div>
                <div>
                  { currentPlan.account_package.name }
                </div>
              </Detail>

              <Detail>
                <div>
                  Period
                </div>
                <div> 
                  :
                </div>
                <div>
                  { `${ dayjs(currentPlan.billing_start_date).format('DD MMM') } - ${ dayjs(currentPlan.billing_end_date).format('DD MMM YYYY') }` }
                </div>
              </Detail>

              <Detail>
                <div />
                <div />
                <CountDay>
                  { `${ dayLeft} days left` }
                </CountDay>
              </Detail>
            </div>

            { /* col 2 */ }
            <div>
              <Detail bold>
                <div>
                  Total
                </div>
                <div> 
                  :
                </div>
                <div>
                  { formatMoney(currentPlan.amount, { currency: currentPlan.currency.name }) }
                </div>
              </Detail>

              <Detail>
                <div>
                  Payment Method
                </div>
                <div> 
                  :
                </div>
                <div>
                  { currentPlan.payment_method.name } { currentPlan.transaction_brand ? ` - ${currentPlan.transaction_brand.brand}` : '' }
                </div>
              </Detail>

              <Detail>
                <div>
                  Status
                </div>
                <div> 
                  :
                </div>
                <Status status={ currentPlan.account.billing_status.name }>
                  { currentPlan.account.billing_status.name }
                </Status>
              </Detail>

              <Detail>
                <div>
                  Invoice
                </div>
                <div> 
                  :
                </div>
                <div>
                  <a href={ currentPlan.hosted_invoice_url } target="_blank" rel="noreferrer">
                    { currentPlan.invoice_no }
                  </a>
                </div>
              </Detail>
            </div>

            { /* col 3 */ }
            <Flex>
              {
                dynamicBtn && (
                  <Link to={ dynamicBtn?.url }>
                    <PaymentButton buttonType='warning'> 
                      { dynamicBtn.title }
                    </PaymentButton>
                  </Link>
                )
              }

              <ShowMore onClick={ () => { setIsShowMore(!isShowMore) } }>
                { isShowMore ? 'Less' : 'More' }
                <Arrow isShowMore={ isShowMore }/>
              </ShowMore>
            </Flex>
          </Grid>
        </CurrentPlanBox>
      )
      }
    </>
  )
}

export default CurrentPlan
