import React from 'react'
import { Breadcrumbs } from 'components/AffiliateDashboard'
import { BillingHistory, CurrentPlan } from './components'
import { Wrapper } from './styles'
import qs from 'query-string'

const ClientManageBillingView = ({ location }) => {
  const queryParams = location.search

  const clientId = qs.parse(queryParams).client_id
  const pageQuery = qs.parse(queryParams).page
  const perPageQuery = qs.parse(queryParams).per_page

  return(
    <Wrapper>
      <Breadcrumbs location={ location } />
      <CurrentPlan clientId={ clientId }/>
      <BillingHistory
        clientId={ clientId }
        pageQuery={ pageQuery }
        perPageQuery={ perPageQuery }
      />
    </Wrapper>
  )
}

export default ClientManageBillingView
