import { ArrowLeft } from '@qlue/icon-kit'
import { AffiliateDashButton } from 'components/AffiliateDashboard'
import { errorValidation } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const CurrentPlanBox = styled.div`
  border: 1px solid #126efa;
  border-radius: 1rem;
  padding: 1.5rem;
  background-color: #fafcff;
`

export const SectionTitle = styled.h3`
  color: #787878;
  font-size: 1.2rem;
  margin-top: 2rem;
  letter-spacing: 0.25px;
  margin-bottom: 1rem;

  ${IS_FRAME_MOBILE} {
    font-size: 1rem;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr 0.7fr;

  ${IS_FRAME_TABLET} {
    display: block;

    > div {
      &:nth-child(2) {
        display: ${({ isShowMore }) => isShowMore ? 'block' : 'none'};
      }

      &:nth-child(3) {
        display: flex;
        justify-content: space-between;
      }
    }
  }


  ${IS_FRAME_MOBILE} {
    display: block;

    > div {
      &:nth-child(2) {
        display: ${({ isShowMore }) => isShowMore ? 'block' : 'none'};
      }

      &:nth-child(3) {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`

export const Detail = styled.div`
  display: flex;
  margin: 0.5rem 0;
  letter-spacing: 0.25px;
  font-size: 0.9rem;

  div {
    &:first-child {
      flex: 0 0 45%;
    }

    &:last-child {
      padding-left: 1rem;
      font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
    }
  }

  ${IS_FRAME_MOBILE} {
    font-size: 0.875rem;
    letter-spacing: 0.25px;
    line-height: 1.25;
  }
`

const STATUS_COLOR = {
  Active   : '#06ab02',
  Canceled : 'orange',
  Expired  : '#e05050'
}

export const Status = styled(Detail)`
  color: ${({ status }) => STATUS_COLOR[status]};
  letter-spacing: 0.25px;
  font-weight: bold !important;
  margin: 0 !important;
`

export const CountDay = styled(Detail)`
  color: ${errorValidation};
  font-weight: bold !important;
  margin: 0 !important;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;

  > a {
    margin-left: auto;
  }

  ${IS_FRAME_TABLET} {
    > a {
      margin-left: 0;
      margin-right: auto;
    }
  }

  ${IS_FRAME_MOBILE} {
    > a {
      margin-left: 0;
      margin-right: auto;
    }
  }
`

export const PaymentButton = styled(AffiliateDashButton)`
  height: fit-content;
  width: fit-content;
  padding: 0.5rem 2rem;
  letter-spacing: 0.5px;

  ${IS_FRAME_TABLET} {
    margin: 0;
    font-weight: 600;
    font-size: 0.875rem;
  }

  ${IS_FRAME_MOBILE} {
    margin: 0;
    font-weight: 600;
    font-size: 0.875rem;
  }
`

export const ShowMore = styled.div`
  display: none;
  color: #8b8d94;
  letter-spacing: 0.25px;
  font-weight: 600;
  align-items: center;

  ${IS_FRAME_TABLET} {
    display: flex;
    font-size: 0.875rem;
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
  }
`

export const Arrow = styled(ArrowLeft)`
  transform: ${({ isShowMore }) => isShowMore ?  'rotate(90deg)' : 'rotate(270deg)'};  
`
